import React, {
  useEffect,
  useReducer,
  Dispatch,
  createContext,
  useMemo,
} from 'react';
import NextNProgress from 'nextjs-progressbar';
import Head from 'next/head';
import reducer from 'stores/reducer';
import initialStore, { initialStoreType } from 'stores';
import { useRouter } from 'next/router';
import { setSideMenu } from 'stores/actions';
import { ToastContainer } from 'react-toastify';

import 'styles/global.css';
import 'remixicon/fonts/remixicon.css';
import 'flag-icon-css/css/flag-icon.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import GTMScript from 'components/GoogleTagManager';
import { NextPageWithLayout } from 'next';
import { AppProps } from 'next/app';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import 'styles/components/RichTextEditor.css';

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (error) {
          return false;
        }
        if (failureCount < 3) {
          return true;
        }

        return false;
      },
      refetchOnWindowFocus: false,
    },
  },
});

export const AppContext = createContext<{
  state: initialStoreType;
  dispatch: Dispatch<Function | { type: string; value: any }>;
}>({
  state: initialStore,
  dispatch: () => void 0,
});

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const [state, dispatch] = useReducer(reducer, initialStore);
  const Router = useRouter();

  useEffect(() => {
    Router.events.on('routeChangeStart', () => {
      dispatch(setSideMenu(false));
    });
  }, []);

  const getLayout = Component.getLayout || ((page) => page);

  const finalComponent = useMemo(() => {
    return getLayout(<Component {...pageProps} />, pageProps);
  }, [Component, pageProps]);

  function renderDebugButton() {
    if (process.env.NEXT_PUBLIC_DEBUG !== 'true') return null;

    return (
      <a
        onClick={() => {
          throw new Error('Test Error Sentry');
        }}
        className="text-white font-bold text-title1 fixed left-16 ipad:left-48 bottom-16 ipad:bottom-48 w-48 h-48 flex items-center justify-center z-30 bg-gradient-to-bl from-primary to-info-light rounded-lg shadow-lg border border-grey-lightest"
      >
        <i className="ri-terminal-line" />
      </a>
    );
  }

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Head>
        <link rel="shortcut icon" type="image/jpeg" href="/images/logo2.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="og:title"
          content="OnBoard Crew App | Portal Karir Untuk Pelaut"
        />
        <meta
          name="og:description"
          content="Raih Karir Pelaut Impian dalam Genggamanmu !"
        />
        <meta
          name="og:image"
          content="https://directus.onboardcrewapp.com/assets/eaf53392-2f9a-49cc-8434-6c7ca1381b85"
        />
        <GTMScript />
      </Head>
      <NextNProgress
        height={6}
        color="#0B7373"
        options={{ showSpinner: false }}
      />
      <QueryClientProvider client={queryClient}>
        {finalComponent}
        {renderDebugButton()}
      </QueryClientProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </AppContext.Provider>
  );
}

export default MyApp;
